import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { createContext, useEffect, useReducer } from 'react';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getSession = () => {
  // const navigate = useNavigate();

  const token = sessionStorage.getItem('accessToken');

  if (!isValidToken(token)) {
    // navigate('/auth/signin')
  }

  return token;
};
const jwtAuthHeader = {
  headers: {
    Authorization: 'Bearer ' + getSession(),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SCHOOLS': {
      var b = {
        ...state,
        loaded: true,
        school: action.payload,
      };

      return b;
    }
    case 'CLEAR_TRANSACTIONS': {
      var b = {
        ...state,
        loadedTranx: false,
        transactions: [],
      };

      return b;
    }
    case 'STATUS_CHANGE': {
      var data = state.allSchoolData;
      var foundIndex = data.users.findIndex(
        (x) => x.phoneNumber == action.payload.data.phoneNumber
      );
      // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
      data.users[foundIndex] = action.payload.data;
      var b = {
        ...state,

        allSchoolData: data,
      };

      return b;
    }
    case 'LOAD_TRANSACTIONS': {
      // // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedTranx: true,
        transactions: action.payload,
      };

      return b;
    }

    case 'LOAD_TRANSACTIONS_OVERVIEW': {
      console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedTranxOver: true,
        transactionsOverView: action.payload,
      };
      console.log('all trabnx data', b);
      return b;
    }

    case 'CLEAR_TRANSACTIONS_OVERVIEW': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedTranxOver: false,
        transactionsOverView: {},
      };

      return b;
    }

    case 'LOAD_TRANSACTIONS_VSAV_OVERVIEW': {
      console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedTranxVasSavings: true,
        transactionsVasSavingsOverView: action.payload,
      };
      console.log('all trabnx data', b);
      return b;
    }

    case 'LOAD_READERS_TRANSACTIONS': {
      var b = {
        ...state,
        loadedReaderTran: true,
        merchant: action.payload,
      };

      return b;
    }

    case 'CLEAR_TRANSACTIONS_READERS': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedReaderTran: false,
        merchant: {},
      };

      return b;
    }

    case 'LOAD_READERS': {
      var b = {
        ...state,
        loadedReader: true,
        readers: action.payload,
      };

      return b;
    }


    case 'CLEAR_READERS': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedReader: false,
        readers: {},
      };

      return b;
    }


    case 'CLEAR_TRANSACTIONS_VSAV_OVERVIEW': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadedTranxVasSavings: false,
        transactionsVasSavingsOverView: {},
      };

      return b;
    }

    case 'CLEAR_EACH_USER': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadUserData: false,
        userData: {},
      };

      return b;
    }
    case 'LOAD_DATE': {
      console.log('date -- ', action.payload);
      var b = {
        ...state,
        date: action.payload,
      };

      return b;
    }


    case 'CLEAR_SCHOOL_ALL':
      {
        var b = {
          ...state,
          loadAllSchoolData: false,
          allSchoolData: {},
        };

        return b;
      }
    case 'LOAD_SCHOOL_ALL':
      {
        var b = {
          ...state,
          loadAllSchoolData: true,
          allSchoolData: action.payload,
        };

        return b;
      }

    case 'LOAD_SEARCH_SCHOOL_ALL':
      {
        var data = state.allSchoolData;
        data.users = action.payload.users;
        var b = {
          ...state,
          loadAllSchoolData: true,
          allSchoolData: data,
        };
        return b;
      }

    case 'START_LOAD_SEARCH_SCHOOL_ALL':
      {
        var b = {
          ...state,
          loadAllSchoolData: false,

        };
        return b;
      }
    case 'LOAD_DOWNLOAD_USER':
      {
        var b = {
          ...state,
          loadDownloadUser: true,

        };
        return b;
      }

    case 'STOP_DOWNLOAD_USER':
      {
        var b = {
          ...state,
          loadDownloadUser: false,

        };
        return b;
      }
    case 'LOAD_EACH_USER': {
      // console.log('all trabnx data', action);
      var b = {
        ...state,
        loadUserData: true,
        userData: action.payload.userData,
      };

      return b;
    }





    case 'ADD_READER': {
      return { ...state };
    }

    default: {
      return { ...state };
    }
  }
};

const SchoolContext = createContext({
  loaded: !false,
  loadedTranx: false,
  loadedTranxOver: false,
  transactions: [],
  merchant: {},
  loadedReaderTran: false,
  transactionsOverView: [],
  school: {},
  loadedTranxVasSavings: false,
  transactionsVasSavingsOverView: {},
  date: 'Today',
  setGeneralDate: () => { },
  getReaderTransactions: () => { },
  allSchoolData: {},
  getSearchReaders: () => { },
  loadAllSchoolData: false,
  loadDownloadUser: false,
  loadUserData: false,
  userData: {},
  loadedReader: false,
  readers: {},
  getAllSchool: () => { },
  getReaders: () => { },
  getSearchAllUser: () => { },
  downloadUser: () => { },
  getEachUser: () => { },
  changeStatus: () => { },
  getDashboard: () => { },
  getNewTransaction: () => { },
  getNewDashboard: () => { },
  getTransactions: () => { },
  getTransactionsOver: () => { },
  getTransactionsVasSavingsOver: () => { },
});

export const SchoolProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);

  const getDashboard = async () => {
    try {
      console.log('------------ kuje I m not the it`s crazy');
      const res = await axios.get(
        'https://www.schoolnode.crediopay.com/api/v1/admin/getOverview',
        jwtAuthHeader
      );
      console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SCHOOLS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const setGeneralDate = async (startDate,) => {
    try {
      dispatch({
        type: 'LOAD_DATE',
        payload: startDate,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const getReaderTransactions = async (date) => {
    const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
    const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");

    try {
      dispatch(
        {
          type: 'CLEAR_TRANSACTIONS_READERS'
        }
      )
      // // console.log('------------ kuje');
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/merchant/overview?startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_READERS_TRANSACTIONS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getSearchAllUser = async (text) => {
    dispatch({
      type: 'START_LOAD_SEARCH_SCHOOL_ALL',
    });
    const res = await axios.get(
      'https://www.api.crediometer.com/api/v1/admin/credio/users/search?search=' + text,
      jwtAuthHeader
    );
    // // console.log('Best detective ---- ', res.data);
    dispatch({
      type: 'LOAD_SEARCH_SCHOOL_ALL',
      payload: res.data,
    });

  };




  const getSearchReaders = async (text) => {
    dispatch({
      type: 'CLEAR_READERS',
    });
    const res = await axios.get(
      'https://www.api.crediometer.com/api/v1/admin/credio/readers/search?search=' + text,
      jwtAuthHeader
    );
    // // console.log('Best detective ---- ', res.data);
    dispatch({
      type: 'LOAD_READERS',
      payload: res.data,
    });

  };

  const downloadUser = async (text) => {
    dispatch({
      type: 'LOAD_DOWNLOAD_USER',
    });

    try {
      const response = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/users/download?type=' + text,
        {
          ...jwtAuthHeader,
          responseType: 'blob',
        }
      );

      const contentDisposition = response.headers['Content-Disposition'];
      let filename = 'downloadedUserFile.xlsx'; // Default filename

      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      dispatch({
        type: 'STOP_DOWNLOAD_USER',
      });
    } catch (error) {
      console.error('Error downloading user:', error);
      // Handle error appropriately
      dispatch({
        type: 'STOP_DOWNLOAD_USER',
      });
    }
  };

  const getNewDashboard = async (date) => {
    try {
      console.log(' -0-0 ', date);
      const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
      const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
      console.log('------------ kuje new get new data ', startDate, ' --- ', endDate); //https://www.api.crediometer.com/
      const res = await axios.get(
        'https://www.schoolnode.crediopay.com/api/v1/admin/getOverview?startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SCHOOLS',
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getNewTransaction = async (date) => {
    try {
      // // console.log('------------ kuje new get new data ', date);
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/transactions/overview',
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SCHOOLS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const getAllSchool = async (type, pageNumber, date) => {
    const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
    const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");

    try {
      dispatch({
        type: 'CLEAR_SCHOOL_ALL',
        payload: {},
      });
      const res = await axios.get(
        'https://www.schoolnode.crediopay.com/api/v1/admin/get/school?type=' + type + '&&pageNumber=' + pageNumber + '&&startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SCHOOL_ALL',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const getTransactions = async (number) => {
    try {
      dispatch({
        type: 'CLEAR_TRANSACTIONS',
        payload: {},
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/transactions?phoneNumber=' + number,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_TRANSACTIONS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getEachUser = async (number) => {
    try {
      dispatch({
        type: 'CLEAR_EACH_USER',
        payload: {},
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/user?phoneNumber=' + number,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_EACH_USER',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getTransactionsOver = async (type, date) => {
    const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
    const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");

    try {
      dispatch({
        type: 'CLEAR_TRANSACTIONS_OVERVIEW',
        payload: {},
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/transactions/overview?type=' + type + '&&startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_TRANSACTIONS_OVERVIEW',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  }; //getTransactionsVasSavingsOver
  const getTransactionsVasSavingsOver = async (type, date) => {
    const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
    const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");

    try {
      dispatch({
        type: 'CLEAR_TRANSACTIONS_VSAV_OVERVIEW',
        payload: {},
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/vas-savings/overview?type=' + type + '&&startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_TRANSACTIONS_VSAV_OVERVIEW',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  }; //getTransactionsVasSavingsOver
  const changeStatus = async (redFlag, number) => {
    try {
      const res = await axios.put(
        'https://www.api.crediometer.com/api/v1/admin/credio/change/status',
        {
          number,
          redFlag,
        }
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'STATUS_CHANGE',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const getReaders = async (date) => {

    const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
    const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");

    try {
      dispatch({
        type: 'CLEAR_READERS',
        payload: {},
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/credio/getReaders?startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_READERS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <SchoolContext.Provider
      value={{
        school: state.school ?? {},
        loaded: state.loaded ?? false,
        loadedReaderTran: state.loadedReaderTran ?? false,
        merchant: state.merchant ?? {},
        loadedTranx: state.loadedTranx ?? false,
        loadedTranxOver: state.loadedTranxOver ?? false,
        transactions: state.transactions ?? [],
        getDashboard,
        loadDownloadUser: state.loadDownloadUser ?? false,
        getEachUser,
        getSearchReaders,
        getReaders,
        readers: state.readers ?? {},
        loadedReader: state.loadedReader ?? false,
        getReaderTransactions,
        loadUserData: state.loadUserData ?? false,
        userData: state.userData ?? {},
        loadedTranxVasSavings: state.loadedTranxVasSavings ?? false,
        transactionsVasSavingsOverView: state.transactionsVasSavingsOverView ?? {},
        getTransactions,
        getSearchAllUser,
        downloadUser,
        getAllSchool,
        loadAllSchoolData: state.loadAllSchoolData ?? false,
        allSchoolData: state.allSchoolData ?? {},
        transactionsOverView: state.transactionsOverView ?? {},
        getNewDashboard,
        date: state.date ?? {
          startDate: new Date(),
          endDate: new Date(),
        },
        setGeneralDate,
        changeStatus,
        getTransactionsOver,
        getTransactionsVasSavingsOver,
        getNewTransaction
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};

export default SchoolContext;
