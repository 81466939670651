import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getSession = () => sessionStorage.getItem('accessToken');

const jwtAuthHeader = {
  headers: {
    Authorization: 'Bearer ' + getSession(),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SAVINGS': {
      var b = {
        ...state,
        loaded: true,
        savings: action.payload,
      };

      return b;
    }

    default: {
      return { ...state };
    }
  }
};

const SupportContext = createContext({
  loaded: !false,
  savings: {},
  getDashboard: () => {},
  getNewDashboard: () => {},
});

export const SupportProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);

  const getDashboard = async () => {
    try {
      // // console.log('------------ kuje');
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/sells/overview',
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SAVINGS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const getNewDashboard = async (date) => {
    try {
      // // console.log('------------ kuje new get new data ', date);
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/sells/overview?date=' + date,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SAVINGS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    // // console.log('cautions fee');
    // if(){
    getDashboard();
    // }else{
    //     getSavings();
    // }
  }, []);

  return (
    <SupportContext.Provider
      value={{
        savings: state.savings ?? {},
        loaded: state.loaded ?? false,
        getDashboard,
        getNewDashboard,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export default SupportContext;
