import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import Loadable from '../../components/Loadable';

import { CredioProvider } from '../../contexts/CredioContext';
const AnalyticCredio = Loadable(lazy(() => import('../credio')));

const dashboardRoutes = [
  {
    path: '/dashboard',
    element: (
      <CredioProvider>
        <AnalyticCredio />
      </CredioProvider>
    ),
    auth: authRoles.sa,
  },
];

export default dashboardRoutes;
//
