import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { createContext, useEffect, useReducer } from 'react';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const loadAgentContent = [
  'LOAD_AGENT_TRANSACTIONS_DEPOSIT',
  'LOAD_AGENT_TRANSACTIONS_WITHDRAWAL',
  'LOAD_AGENT_USERS_ADVANCE',
  'LOAD_AGENT_TRANSACTIONS_ADVANCE_PENDING',
  'LOAD_AGENT_TRANSACTIONS_ADVANCE_APPROVED',
  'LOAD_AGENT_TRANSACTIONS_ADVANCE_AWAITING',
  'LOAD_AGENT_TRANSACTIONS_ADVANCE_DISBURSED',
  'LOAD_AGENT_NEW_USERS',
];

const requestAgentContent = [
  'REQUEST_AGENT_TRANSACTIONS_DEPOSIT',
  'REQUEST_AGENT_TRANSACTIONS_WITHDRAWAL',
  'REQUEST_AGENT_TRANSACTIONS_USERS',
  'REQUEST_AGENT_TRANSACTIONS_ADVANCE_PENDING',
  'REQUEST_AGENT_TRANSACTIONS_ADVANCE_APPROVED',
  'REQUEST_AGENT_TRANSACTIONS_ADVANCE_AWAITING',
  'REQUEST_AGENT_TRANSACTIONS_ADVANCE_DISBURSED',
  'REQUEST_AGENT_TRANSACTIONS_NEW_USERS'
];

const loadUserContent = [
  'LOAD_USER_TRANSACTIONS_DEPOSIT',
  'LOAD_USER_TRANSACTIONS_WITHDRAWAL',
  'LOAD_USERS_ADVANCE',
];

const requestUserContent = [
  'REQUEST_USER_TRANSACTIONS_DEPOSIT',
  'REQUEST_USER_TRANSACTIONS_WITHDRAWAL',
  'REQUEST_USER_TRANSACTIONS_ADVANCE'
];

const getSession = () => {
  // const navigate = useNavigate();

  const token = sessionStorage.getItem('accessToken');

  if (!isValidToken(token)) {
    // navigate('/auth/signin')
  }

  return token;
};
const jwtAuthHeader = {
  headers: {
    Authorization: 'Bearer ' + getSession(),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SAVINGS': {
      var b = {
        ...state,
        loaded: true,
        savings: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_TRANSACTIONS_DEPOSIT': {

      var b = {
        ...state,
        loadedDeposit: true,
        deposit: action.payload,
      };
      console.log("ghj ", b);
      return b;
    }

    case 'LOAD_PUMPS': {
      var b = {
        ...state,
        loadedPumps: true,
        pumps: action.payload,
      };
      console.log("ghj ", b);
      return b;
    }

    case 'ADD_PUMPS': {
      var newPumps = [...state.pumps, action.payload];
      var b = {
        ...state,
        loadedAddedPump: true,
        pumps: newPumps,
      };

      return b;
    }

    case 'CLEAR_ADD_PUMPS': {

      var b = {
        ...state,
        loadedAddedPump: false,

      };
      console.log("ghj ", b);
      return b;
    }


    case 'CLEAR_PUMPS': {

      var b = {
        ...state,
        loadedPumps: false,
        pumps: [],
      };
      console.log("ghj ", b);
      return b;
    }


    case 'EDIT_USER_NAME': {

      var data = state.agentUsers;
      var foundIndex = data.users.findIndex(
        (x) => x.phoneNumber == action.payload.data.phoneNumber
      );
      // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
      data.users[foundIndex] = action.payload.data;
      var b = {
        ...state,

        agentUsers: data,
        loadingChangeName: false,
      };

      return b;
    }
    case 'LOAD_DATE': {
      console.log('date -- ', action.payload);
      var b = {
        ...state,
        date: action.payload,
      };

      return b;
    }

    case 'LOAD_AGENT_TRANSACTIONS_WITHDRAWAL': {
      var b = {
        ...state,
        loadedWithdrawal: true,
        withdrawal: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_TRANSACTIONS_ADVANCE_PENDING': {
      var b = {
        ...state,
        loadedPendingAdvance: true,
        advancePending: action.payload,
      };
      return b;
    }

    case 'LOAD_SAVINGS_AGENTS': {
      var b = {
        ...state,
        loadedAgents: true,
        agents: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_DETAILS': {
      var b = {
        ...state,
        loadAgentDetails: true,
        agentDetails: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_NEW_USERS': {
      var b = {
        ...state,
        loadedAgentnewUsers: true,
        agentNewUsers: action.payload,
      };
      return b;
    }

    case 'LOAD_AGENT_TRANSACTIONS_ADVANCE_APPROVED': {
      var b = {
        ...state,
        loadedApprovedAdvance: true,
        advanceApproved: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_TRANSACTIONS_ADVANCE_AWAITING': {
      var b = {
        ...state,
        loadedAwaitingAdvance: true,
        advanceAwaiting: action.payload,
      };
      return b;
    }
    case 'LOAD_AGENT_TRANSACTIONS_ADVANCE_DISBURSED': {
      var b = {
        ...state,
        loadedDisbursedAdvance: true,
        advanceDisbursed: action.payload,
      };
      return b;
    }

    case 'RESET_USER_NAME':
      {
        var b = {
          ...state,
          loadingChangeName: action.payload,
        };
        return b;
      }
    case 'LOAD_AGENT_USERS_ADVANCE': {
      console.log("sfsdg");
      var b = {
        ...state,
        loadedAgentUsers: true,
        agentUsers: action.payload,
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_DEPOSIT': {
      var b = {
        ...state,
        deposit: {},
        loadedDeposit: false,
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_WITHDRAWAL': {
      var b = {
        ...state,
        loadedWithdrawal: false,
        withdrawal: {},
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_ADVANCE_PENDING': {
      var b = {
        ...state,
        loadedPendingAdvance: false,
        advancePending: {}
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_ADVANCE_APPROVED': {
      var b = {
        ...state,
        loadedApprovedAdvance: false,
        advanceApproved: {}
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_ADVANCE_AWAITING': {
      var b = {
        ...state,
        loadedAwaitingAdvance: false,
        advanceAwaiting: {}
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_ADVANCE_DISBURSED': {
      var b = {
        ...state,
        loadedDisbursedAdvance: false,
        advanceDisbursed: {}
      };
      return b;
    }
    case 'REQUEST_AGENT_TRANSACTIONS_USERS': {
      var b = {
        ...state,
        loadedAgentUsers: false,
        agentUsers: {}
      };
      return b;
    }

    case 'REQUEST_AGENT_TRANSACTIONS_NEW_USERS': {
      var b = {
        ...state,
        loadedAgentNewUsers: false,
        agentNewUsers: {}
      };
      return b;
    }

    case 'UPDATE_ADVANCE_TRANSACTION_STATUS': {
      var b;
      if (action.from == 1) {
        var data = state.advancePending;
        data.transactions = data.transactions.filter(
          (x, index) => x._id != action.payload.data._id
        );

        var b = {
          ...state,

          advancePending: data,
        };
      } else if (action.from == 2) {
        var data = state.advanceApproved;
        data.transactions = data.transactions.filter(
          (x, index) => x._id != action.payload.data._id
        );

        var b = {
          ...state,

          advanceApproved: data,
        };
      } else {
        var data = state.advanceAwaiting;
        data.transactions = data.transactions.filter(
          (x, index) => x._id != action.payload.data._id
        );

        var b = {
          ...state,

          advanceAwaiting: data,
        };
      }
      console.log(action.payload.data._id, " data -- ", data, ' type -- ', action.payload.type);
      if (action.status == 3) {
        b = {
          ...b,
          loadedAwaitingAdvance: false,
          advanceAwaiting: {}
        }

      } else if (action.status == 8) {
        b = {
          ...b,
          loadedDisbursedAdvance: false,
          advanceDisbursed: {}
        }

      } else if (action.status == 5) {
        b = {
          ...b,
          loadedApprovedAdvance: false,
          advanceApproved: {}
        }
      }

      return b;
    }
    case 'UPDATE_TRANSACTION_AMOUNT': {
      // var b;
      if (action.from == 1) {
        var data = state.deposit;
        var foundIndex = data.transactions.findIndex(
          (x) => x._id == action.payload.data._id
        );
        // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
        data.transactions[foundIndex] = action.payload.data;
        var b = {
          ...state,

          deposit: data,
        };

      } else if (action.from == 2) {
        var data = state.withdrawal;
        var foundIndex = data.transactions.findIndex(
          (x) => x._id == action.payload.data._id
        );
        // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
        data.transactions[foundIndex] = action.payload.data;
        var b = {
          ...state,

          withdrawal: data,
        };

      } else if (action.from == 3) {
        var data = state.advancePending;
        var foundIndex = data.transactions.findIndex(
          (x) => x._id == action.payload.data._id
        );
        // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
        data.transactions[foundIndex] = action.payload.data;
        var b = {
          ...state,

          advancePending: data,
        };
      } else if (action.from == 4) {
        var data = state.advanceApproved;
        var foundIndex = data.transactions.findIndex(
          (x) => x._id == action.payload.data._id
        );
        // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
        data.transactions[foundIndex] = action.payload.data;
        var b = {
          ...state,

          advanceApproved: data,
        };
      } else {
        var data = state.advanceAwaiting;
        var foundIndex = data.transactions.findIndex(
          (x) => x._id == action.payload.data._id
        );
        // // console.log('cleaning agent --- ', data.data.allUsers[foundIndex]);
        data.transactions[foundIndex] = action.payload.data;
        var b = {
          ...state,

          advanceAwaiting: data,
        };
      }


      return b;
    }

    default: {
      return { ...state };
    }
  }
};

const SavingsContext = createContext({
  loaded: !false,
  loadedApprovedAdvance: false,
  loadedPendingAdvance: false,
  loadedDisbursedAdvance: false,
  loadedAwaitingAdvance: false,
  loadedDeposit: false,
  loadedPumps: false,
  loadedAgents: false,
  loadedAddedPump: false,
  pumps: {},
  agents: {},
  date: 'Today',
  setGeneralDate: () => { },
  getPumps: () => { },
  addPump: () => { },
  loadAgentDetails: false,
  agentDetails: {},
  getAgentDetails: async () => { },
  getAgents: async () => { },
  loadedWithdrawal: false,
  loadingChangeName: false,
  loadingChangeRate: false,
  deposit: {},
  withdrawal: {},
  savings: {},
  updateSavingsRate: async () => { },
  updateBusinessName: async () => { }, //updateTransactionAmount
  updateTransactionAmount: async () => { },//
  advancePending: {},
  advanceApproved: {},
  advanceAwaiting: {},
  advanceDisbursed: {},
  getDashboard: () => { },
  updateAdvanceTransactionStatus: async () => { },
  loadedAdvance: false,
  agentUsers: {},
  loadedAgentUsers: false, //loadedAgentnewUsers
  loadedAgentnewUsers: false, //
  loadedDeposit: false,
  agentNewUsers: {},
  loadedWithdrawal: false,
  getAgentTransaction: () => { },
  getAgents: () => { },
  getUserTransaction: () => { },
  getNewDashboard: () => { },
});

export const SavingsProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);

  const getDashboard = async () => {
    try {
      // // console.log('------------ kuje');
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/savings/overview',
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SAVINGS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };

  const setGeneralDate = async (startDate,) => {
    try {
      dispatch({
        type: 'LOAD_DATE',
        payload: startDate,
      });
    } catch (e) {
      // console.error(e);
    }
  };
  const getAgents = async () => {
    try {
      // // console.log('------------ kuje');
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/savings/get/agents',
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SAVINGS_AGENTS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };
  //


  const getPumps = async (type, date) => {
    try {
      console.log('---/--------- kuje');
      dispatch({
        type: 'CLEAR_PUMPS',
      });
      let url = 'https://www.api.crediometer.com/api/v1/admin/savings/pumps?type=' + type;
      console.log(' -0-0 ', date);
      if (date) {
        const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
        const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
        url = url + '&&startDate=' + startDate + '&&endDate=' + endDate;
      }

      const res = await axios.get(
        url,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_PUMPS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };


  const addPump = async (purpose, source, amount, administrator, type) => {
    try {

      dispatch({
        type: 'CLEAR_ADD_PUMPS',
      });
      const res = await axios.post(
        'https://www.api.crediometer.com/api/v1/admin/savings/pumps',
        {
          purpose,
          source,
          amount,
          administrator,
          type
        },
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'ADD_PUMPS',
        payload: res.data,
      });
      return;
    } catch (e) {
      dispatch({
        type: 'RESET_USER_NAME',
        payload: false,
      });
      // console.error(e);
      return;
    }
  };


  const updateSavingsRate = async (phoneNumber, rate) => {
    try {

      dispatch({
        type: 'RESET_USER_NAME',
        payload: true,
      });
      const res = await axios.put(
        'https://www.api.crediometer.com/api/v1/admin/savings/agents/user/updateBusinessName',
        {
          phoneNumber,
          rate,
        },
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'EDIT_USER_NAME',
        payload: res.data,
      });
      return;
    } catch (e) {
      dispatch({
        type: 'RESET_USER_NAME',
        payload: false,
      });
      // console.error(e);
      return;
    }
  };


  const updateBusinessName = async (phoneNumber, businessName) => {
    try {

      dispatch({
        type: 'RESET_USER_NAME',
        payload: true,
      });
      const res = await axios.put(
        'https://www.api.crediometer.com/api/v1/admin/savings/agents/user/updateBusinessName',
        {
          phoneNumber,
          businessName,
        },
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'EDIT_USER_NAME',
        payload: res.data,
      });
      return;
    } catch (e) {
      dispatch({
        type: 'RESET_USER_NAME',
        payload: false,
      });
      // console.error(e);
      return;
    }
  };

  const getAgentDetails = async (phoneNumber, date) => {
    try {
      let url = 'https://www.api.crediometer.com/api/v1/admin/savings/get/agents/details?phoneNumber=' + phoneNumber;
      if (date) {

        console.log(' -0-0 ', date);
        const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
        const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
        console.log('------------ kuje new get new data ', startDate, ' --- ', endDate); //https://www.api.crediometer.com/
        url = url + '&&startDate=' + startDate + '&&endDate=' + endDate;
      }
      const res = await axios.get(
        url,

        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_AGENT_DETAILS',
        payload: res.data,
      });
      return;
    } catch (e) {

      // console.error(e);
      return;
    }
  }

  const updateAdvanceTransactionStatus = async (id, status, type) => {
    try {

      const res = await axios.put(
        'https://www.api.crediometer.com/api/v1/admin/savings/agents/transaction/updateStatus',
        {
          id,
          status,
        },
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'UPDATE_ADVANCE_TRANSACTION_STATUS',
        payload: res.data,
        from: type,
        status
      });
      return;
    } catch (e) {

      // console.error(e);
      return;
    }
  };

  const updateTransactionAmount = async (id, amount, type) => {
    try {

      const res = await axios.put(
        'https://www.api.crediometer.com/api/v1/admin/savings/agents/user/updateTransactionAmount',
        {
          id,
          newAmount: amount,
        },
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'UPDATE_TRANSACTION_AMOUNT',
        payload: res.data,
        from: type,
      });
      return;
    } catch (e) {

      // console.error(e);
      return;
    }
  };

  const getNewDashboard = async (date) => {
    try {

      console.log(' -0-0 ', date);
      const startDate = moment(new Date(date.startDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
      const endDate = moment(new Date(date.endDate)).format("YYYY-MM-DD[T00:00:00.000Z]");
      console.log('------------ kuje new get new data ', startDate, ' --- ', endDate); //https://www.api.crediometer.com/

      // // console.log('------------ kuje new get new data ', date);
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/savings/overview?startDate=' + startDate + '&&endDate=' + endDate,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: 'LOAD_SAVINGS',
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };
  const getAgentTransaction = async (type, phoneNumber, date) => {
    try {
      console.log('------------ kuje new get new data ', type);

      let url = 'https://www.api.crediometer.com/api/v1/admin/savings/agents?type=' + type + "&&phoneNumber=" + phoneNumber;

      dispatch({
        type: requestAgentContent[type],
      });
      const res = await axios.get(
        url,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: loadAgentContent[type],
        payload: res.data,
      });
      console.log("res.data ", res.data, loadAgentContent[type])
    } catch (e) {
      // console.error(e);
    }
  };
  const getUserTransaction = async (type, phoneNumber) => {
    try {
      // // console.log('------------ kuje new get new data ', date);
      dispatch({
        type: requestUserContent[type],
      });
      const res = await axios.get(
        'https://www.api.crediometer.com/api/v1/admin/savings/users?type=' + type+'&&phoneNumber='+phoneNumber,
        jwtAuthHeader
      );
      // // console.log('Best detective ---- ', res.data);
      dispatch({
        type: loadUserContent[type],
        payload: res.data,
      });
    } catch (e) {
      // console.error(e);
    }
  };
  useEffect(() => {
    // // console.log('cautions fee');
    // if(){
    getDashboard();
    // }else{
    //     getSavings();
    // }
  }, []);

  return (
    <SavingsContext.Provider
      value={{
        savings: state.savings ?? {},
        loaded: state.loaded ?? false,//loadedPumps
        loadedPumps: state.loadedPumps ?? false,//
        pumps: state.pumps ?? {},//
        deposit: state.deposit ?? {},
        advancePending: state.advancePending ?? {},
        advanceApproved: state.advanceApproved ?? {},
        advanceAwaiting: state.advanceAwaiting ?? {},
        advanceDisbursed: state.advanceDisbursed ?? {},
        withdrawal: state.withdrawal ?? {},
        agentUsers: state.agentUsers ?? {},
        loadedAgentUsers: state.loadedAgentUsers ?? false,//loadedAgentnewUsers
        loadedAgentnewUsers: state.loadedAgentnewUsers ?? false,//
        loadedDeposit: state.loadedDeposit ?? false,
        loadedApprovedAdvance: state.loadedApprovedAdvance ?? false,
        loadedDisbursedAdvance: state.loadedDisbursedAdvance ?? false,
        loadedPendingAdvance: state.loadedPendingAdvance ?? false,
        loadedAwaitingAdvance: state.loadedAwaitingAdvance ?? false,
        loadedWithdrawal: state.loadedWithdrawal ?? false,
        loadedAddedPump: state.loadedAddedPump ?? false,
        loadingChangeName: state.loadingChangeName ?? false,
        loadingChangeRate: state.loadingChangeRate ?? false,
        getDashboard,
        getPumps,
        addPump,
        agentNewUsers: state.agentNewUsers ?? {},
        updateTransactionAmount,
        updateAdvanceTransactionStatus,
        getUserTransaction,
        updateBusinessName,
        date: state.date ?? {
          startDate: new Date(),
          endDate: new Date(),
        },
        setGeneralDate,
        updateSavingsRate,
        loadedAgents: state.loadedAgents ?? false,
        agents: state.agents ?? {},
        agentDetails: state.agentDetails ?? {},
        loadAgentDetails: state.loadAgentDetails ?? false,
        getAgents,

        getAgentTransaction,
        getAgentDetails,
        getNewDashboard,
      }}
    >
      {children}
    </SavingsContext.Provider>
  );
};

export default SavingsContext;
