
exports.pub_key = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoZq/tYsNVzHYntetmCCC
NPl1KTwQ0sWKZZUFilbUHA+8y0+gWLZDgIvevEkip0JznQznSb4VDCIN1MTwI3kV
+5II9F6Y8tuucG85LzOLOl0z3jVAyqg8B++tWnSoxlw73EfQu2xvYfDg86f9P0Oi
KDMzkeL7xAgJ8wHiXBmrrQoMt3QWwcV6NPNMFxjTpYGGz8JsaGRoCEZsl+f9RXCx
X9SIQ3xYKjNozE8AT6jBBDdgd82ivHLYbtoBuZNB54p4MBaPXX6XCCndR9MbChhx
IJ9hv256bKm7mcrquU6nPQ9GbftV4XdHWj9W9EycJ8zalHiaSufT49d+cvOBjzi/
jgU0+tY+anUB8iAKnW7UhyCco/wVuVYqmHagACPRE/yvJe/6km/hZgfh1hLB5zgt
9PJnzfTTI4hNsd66UK4G++KlYBqeSevGc43RReJrAUfFuAT6SmNv7LgSl4nDURqU
+XO+r/30JZoHpFzsQPm8mh/1aTtTG2Q/Dgp71sxUVrQJRVmtmNbBslkRFkWdAC4+
gOQiPQm438t2rjVqGQEIwcfs3peQqlpaYhiZB5WB1ds8ByBVK4euyXdNOv/7CxWz
TSe/oJd21CiDbXVRqhiViiagqGklu9OMAb7z8rO2Nh6Eqf4/bmnd12K/wmHq7xiF
iN+IZWhdWu6jyg6BDCZjTNUCAwEAAQ==
-----END PUBLIC KEY-----`

